import React from 'react';

import useAmazonConnect from '../../hooks/useAmazonConnect.js';

function App({ children, amazonConnectConfig = {} }) {

    useAmazonConnect(amazonConnectConfig);

    return (<>{children}</>);
}

export default App;
