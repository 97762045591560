import ReactDOM from 'react-dom';
import React from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import 'whatwg-fetch';
import 'polyfill-array-includes';
import 'core-js/modules/es.array.from.js';
import 'core-js/modules/es.array.includes.js';
import 'core-js/modules/es.string.includes.js';
import 'core-js/modules/es.object.values.js';

import Routing from './containers/Routing.jsx';

import { persistor, store } from './reducers/reducer.js';

import './common/polyfills';
import shouldPurge from './helpers/should_purge.js';
import App from './components/app/App.jsx';

const renderElement = document.getElementById('react-ap');

if (shouldPurge()) {
    persistor.purge();
}


if (renderElement) {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App
                    amazonConnectConfig={JSON.parse(renderElement?.getAttribute('data-amazon-connect-config') || '{}')}
                >
                    <Routing
                        tab={renderElement.getAttribute('data-tab')}
                        environment={renderElement.getAttribute('data-environment')}
                    />
                </App>
            </PersistGate>
        </Provider>,
        renderElement,
    );
}

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store;
}
